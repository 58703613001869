
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { ModalToolbarAction } from "@/components/shared/layout/Modal.vue";

@Component({})
export default class ModalToolbar extends Vue {
  @Prop({})
  toolbarTitle!: string;
  @Prop({})
  actions!: ModalToolbarAction[];

  @Emit()
  actionClicked(name: string) {
    //abc
  }
}
