export class Service {
  dialogs: HTMLDivElement[] = [];

  addDialog(div: HTMLDivElement) {
    this.dialogs.push(div);
  }

  removeDialog() {
    this.dialogs.splice(this.dialogs.length - 1, 1);
    if (this.dialogs.length > 0) {
      const dialog = this.dialogs[this.dialogs.length - 1];
      dialog.focus();
    }
  }
}

export const DialogService = new Service();
