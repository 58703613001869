
import { Component, Vue } from "vue-property-decorator";
import {
  OnboardingApi,
  Agency,
} from "@fundraisingbureauet/admin-typescript-client";
import { AuthInterceptor } from "@/config";
import ElementList, {
  ListColumn,
} from "@/components/shared/layout/ElementList.vue";
import AgencyDialog from "@/components/agencies/AgencyDialog.vue";

@Component({
  components: { AgencyDialog, ElementList },
})
export default class AgenciesList extends Vue {
  agencies: Array<Agency> = [];
  search = "";
  newAgency = false;
  columns: ListColumn<Agency>[] = [
    {
      header: "Name",
      key: "agencyName",
      field: "agencyName",
    },
    {
      header: "Agency Id",
      key: "agencyId",
      field: "agencyId",
    },
    {
      header: "IP List Enabled",
      key: "ipListEnabled",
      field: "ipListEnabled",
    },
  ];

  created() {
    this.refresh();
  }

  agencyAdded() {
    this.refresh();
  }

  closeNewAgencyModal() {
    this.newAgency = false;
  }

  filterAgencies(agency: Agency, filter: string) {
    if (filter === "") {
      return agency;
    } else {
      return (
        agency.agencyName?.toLowerCase().includes(filter.toLowerCase()) ||
        agency.agencyId?.toLowerCase().includes(filter.toLowerCase())
      );
    }
  }

  private refresh() {
    new OnboardingApi(AuthInterceptor.Instance)
      .listAgencies()
      .subscribe((value) => {
        this.agencies = value;
      });
    this.newAgency = false;
  }
}
